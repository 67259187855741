// Body
$body-bg: #ffffff;

// Typography
$font-family-sans-serif: 'Open Sans', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Header
$header-height: 153px;
$header-height-tablet: 105px;
$header-height-mobile: 73px;

// Footer
$footer-height: 169px;
$footer-height-mobile: 90px;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #458C23;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$mainColor: #FFD457;
$secondColor: #8CCEE8;
$thirdColor: #795300;