// Fonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300%3b400%3b700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300%3b400%3b600%3b700&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

//Font Awesome
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

//FancyBox
//@import '~@fancyapps/ui/dist/fancybox';

//Slick Slider
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

//Select2
//@import '~select2/dist/css/select2.min';

//Animate
//@import '~animate.css/animate.css';

@import '../css/default.css';
@import '../css/component.css';
@import '../css/style.default.css';

html, body{
    font-family: 'Open Sans', sans-serif;
    image-rendering: -webkit-optimize-contrast;
    color: #000;
    scroll-behavior: smooth;
}

main{
    padding-top: $header-height-mobile;
    min-height: calc(100vh - #{$footer-height-mobile});
    background-color: #fff;
}

a{
    color: #000;
    &:hover{
        text-decoration: none;
        color: #000;
    }
}
h1{
    font-size: 26px;
}
h3{
    font-weight: 600;
    font-size: 26px;
}
h4{
    font-size: 16px;
}
p{
    font-size: 13px;
    a{
        text-decoration: underline;
    }
}
.btn:focus, .btn.focus{
    box-shadow: none;
}
.breadcrumb-item{
    a:hover{
        text-decoration: underline;
    }
}
.home{
    .navbar-laravel {
        .navbar-brand{
            .default{
                opacity: 1;
            }
            .scroll-logo{
                opacity: 0;
            }
        }
        &.scrolling{
            box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 40%);
            .navbar-brand{
                .default{
                    opacity: 0;
                }
                .scroll-logo{
                    opacity: 1;
                }
            }
        }
    }
    .quotes{
        top: -53px;
        bottom: unset;
    }
}
.page.landing-page{
    header, section.newsletter, .bread-container{
        display: none;
    }
    main{
        padding-top: 0;
    }
}
.fs-12{
    font-size: 12px;
}
.navbar-laravel {
    padding: 0;
    .navbar-brand{
        position: relative;
        .default{
            opacity: 0;
        }
        .scroll-logo{
            opacity: 1;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        img{
            transition: all .4s ease-in-out;
        }
    } 
    .search-bar{
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        input{
            border-radius: 57px;
            background-color: #fff;
            height: 50px;
            padding-left: 1.5rem;
            &:focus + .search-button{
                font-size: 1.2rem;
            }
        }
        .search-button{
            position: absolute;
            background-color: $secondColor;
            height: 40px;
            width: 40px;
            border-radius: 20px;
            right: 7px;
            display: grid;
            place-items: center;
            padding: 0;
            border: none;
            &:hover{
                width: 65px;
            }
        }
    }
    .support{
        font-family: 'Open Sans', sans-serif;
        .icon{
            color: $mainColor;
        }
        .text{
            color: #fff;
            a{
                color: #fff;
            }
            small{
                font-size: 15px;
            }
            p{
                line-height: 1;
                small{
                    font-size: 10px;
                }
            } 
            h5{
                font-size: 15px;
            }
        }
    }
    .icon{
        font-size: 22px;
    }
    .flag{
        width: 30px;
    }
    .bordered{
        display: grid;
        place-items: center;
        width: 55px;
        i{
            margin: auto;
        }
        .dropdown-toggle + .dropdown-menu{
            background-color: $mainColor;
            padding: 5px 8px;
            border-radius: 0.25rem;
            .nav-link{
                color: #fff;
                font-weight: 700;
                text-align: center;
            }
        }
    }
    .toggler-menu{
        color: #fff;
        font-size: 18px;
        background-color: transparent;
        border: none;
        padding: 0.25rem 0.5rem;
        &.active{
            color: $mainColor;
        }
    }
    .cart-toggler, .favorites-toggler{
        position: relative;
        .overhead{
            position: absolute;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #fff;
            color: #000;
            display: grid;
            place-items: center;
            top: -3px;
            right: -6px;
            font-size: 11px;
        }
    }
    .navbar-toggler{
        color: #000;
        background-color: $mainColor;
        border-radius: 50%;
        width: 31px;
        height: 31px;
        i{
            font-size: 16px;
        }
    }
    .navbar-nav{
        .nav-item{
            .nav-link{
                color: #000;
                padding: 0 10px;
                font-size: 14px;
                font-weight: bold;
                display: flex;
                align-items: center;
                &.active{
                    color: $mainColor;
                }
                .icon{
                    border-radius: 50%;
                    border: 1px solid $mainColor;
                    display: grid;
                    place-items: center;
                    padding: 5px;
                    width: 40px;
                    height: 40px;
                    color: $secondColor;
                }
            }
        }
        .dropdown-toggle{
            color: $mainColor;
            cursor: pointer;
            &::after{
                border: none;
                content: '\f107';
                font-family: 'Font Awesome 5 Free';
                color: $mainColor;
                margin-left: 10px;
                font-size: 16px;
                font-weight: 900;
            }
        }
        
    }
    .menu-assets{
        .navbar-nav{
            .nav-item{
                .nav-link{
                    text-transform: none;
                    position: relative;
                    .overhead{
                        position: absolute;
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        background-color: #fff;
                        color: #000;
                        display: grid;
                        place-items: center;
                        top: -10px;
                        right: 0;
                        font-size: 11px;
                    }
                }
            }
        }
    }
    &.shade{
        box-shadow: 0px -3px 8px 0px #000;
    }
}
.underline{
    text-decoration: underline;
}
.title{
    display: inline-block;
    position: relative;
    &::before{
        content: '';
        width: 100%;
        height: 15px;
        background-color: $mainColor;
        border-radius: 30px;
        position: absolute;
        bottom: 5px;
        left: 0;
    }
    h1, h2, h3, h4{
        position: relative;
        padding: 0 20px;
        font-size: 20px;
        font-weight: 700;
    }
}

.footer{
    padding: 25px 0px 15px;
    color: #fff;
    background-color: $mainColor;
    p{
        color: #fff;
        font-size: 13px;
    }
    .social{
        a{
            font-size: 16px;
            background-color: #fff;
            color: #000;
            border-radius: 50%;
            width: 34px;
            height: 34px;
            display: grid;
            place-items: center;
            &:hover{
                color: $mainColor;
            }
        }
    }
    .nav-link{
        font-size: 11px;
        color: #fff;
        text-transform: uppercase;
        &:hover, &.active{
            color: $mainColor;
        }
    }
    .button{
        background-color: #fff;
        border-radius: 30px;
        color: #000;
        padding: 5px 10px;
        font-size: 13px;
        p{
            color: #000;
            .bigger{
                font-size: 15px;
            }
        }
        i{
            font-size: 22px;
        }
    }
}
.dropdown-item{
    cursor: pointer;
}
.menu-mobile{
    display: flex;
    width: 340px;
    max-width: 340px;
    height: calc(100vh - #{$header-height-mobile});
    overflow: auto;
    position: fixed;
    top: $header-height-mobile;
    right: -360px;
    background: $mainColor;
    transition: all 1s ease;
    
    .dl-menu{
        position: relative;
        li a{
            padding: 15px;
        }
    }
    &.active{
        z-index: 999;
        right: 0;
    }
    .header-top{
        padding: 10px 20px;
        background-color: $mainColor;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        text-align: center;
    }
    .header-bottom{
        padding-bottom: 60px;
        .nav-item{
            .nav-link{
                color: #000;
                font-size: 16px;
                font-weight: 700;
                &.active{
                    color: #fff;
                }
            }
        }
        li a{
            color: #000;
            font-weight: 700;
            &.active{
                color: #fff;
            }
        }
        .dropdown-menu{
            position: relative;
            background-color: transparent;
            text-align: center;
        }
        .dropdown-item{
            font-weight: bold;
            color: #fff;
            &.active, &:active, &:hover, &:focus{
                background-color: $secondColor;
            }
        }
        .nav-link{
            padding: 10px 20px;
        }
    }
    .close{
        position: absolute;
        top: 3rem;
        right: 3rem;
        border: none;
        background-color: transparent;
        opacity: 1;
        i{
            font-size: 40px;
            color: #fff;
        }
    }
}
.mobile-search-box{
    transition: all .3s ease-in;
    max-height: 0;
    overflow: hidden;
    &.active{
        max-height: 50px;
        margin-top: .5rem;
    }
}
.cart-mobile{
    table{
        th{
            padding-right: .25rem;
        }
        .quantity-update{
            width: 50px;
        }
    }
}

.cta{
    color: #fff;
    background-color: #795300;
    border: 1px solid #795300;
    border-radius: 45px;
    padding: .5rem 4rem .5rem 1rem;
    display: inline-block;
    font-size: 13px;
    position: relative;
    transition: 1s all ease;
    overflow: hidden;
    z-index: 1;
    text-decoration: none;
    font-weight: bold;
    &:hover{
        color: #fff;
        text-decoration: none;
        padding-right: 5rem;
        &::before{
            transform: translateY(-50%) rotateZ(90deg);
        }
    }
    &::before{
        content: '\f054';
        font-weight: 900;
        font-family: 'Font Awesome 5 Free';
        background-color: #fff;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        color: #795300;
        display: grid;
        place-items: center;
        transition: 1s all ease;
    }
    &.arrow-back::before{
        content: '\f053';
    }
    &.cta-fix{
        &:hover{
            padding-right: 4rem;
        }
    }
    &.cta-blue{
        background-color: $secondColor;
        color: #000;
        border-color: $secondColor;
        &::before{
            color: #000;
        }
    }
    &.cta-black{
        background-color: #000;
        color: #fff;
        &::before{
            color: #000;
        }
    }
    &.cta-white{
        background-color: #fff;
        color: #000;
        &::before{
            color: #fff;
            background-color: #000;
        }
    }
}
#basket form .cta{
    background-color: #795300;
}

.cookie-consent{
    z-index: 20000000000!important;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: rgba(0,0,0,0.7);
    color: #fff;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .cookie-consent__message{
        font-size: 15px;
        color: #000;
        padding: 8px 0 0;
        font-weight: bold;
        text-align: left;
        a{
            color: #000;
            text-decoration: underline;
        }
    }
    .cookie-consent__agree{
        margin-left: .5rem;
        &:hover{
            background-color: #fff;
        }
        &.cta{
            color: #fff;
            background-color: #795300;
        }
    }
    .cta{
        border: 1px solid #795300;
        background-color: $mainColor;
        &:hover{
            padding-right: 4rem;
        }
    }
    .card-body{
        background-color: $mainColor;
        .actions{
            padding: 15px 0;
        }
    }
}
.form-check{
    padding-left: 0;
}
.form-check-label{
    padding-left: 1rem;
}
input{
    font-family: 'Open Sans', sans-serif;
}
input[type="checkbox"], input[type="radio"]{
    position: relative;
    margin-left: 7px;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    &::before{
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        top: -3px;
        left: -3px;
        border: 1px solid #000;
        border-radius: 5px;
        font-size: 12px;
        background-color: #fff;
        cursor: pointer;
    }
    &:checked::before{
        background-color: $mainColor;
        border-color: $mainColor;
        content: "\F00C";
        text-align: center;
    }
}
input[type="checkbox"]:disabled{
    opacity: .5;
}

input[type="submit"]{
    background-color: #fff;
}

label{
    font-size: 13px;
}

.form-control{
    border: 1px solid #000000;
    border-radius: 10px;
    font-size: 13px;
    &:focus{
        border: 1px solid #000;
        outline: none;
        box-shadow: none;
    }
}
.select2-container--default .select2-selection--single .select2-selection__arrow{
    top: 5px;
}
.select2-container--default .select2-selection--single{
    height: calc(1.6em + 0.75rem + 2px);
    display: flex;
    align-items: center;
    border: 1px solid $mainColor;
}
.font-15{
    font-size: 15px;
}
.modal-header{
    border: none;
    background-color: #795300;
    padding: .5rem 1rem;
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.modal-content{
    border-radius: 10px;
    border: none;
}
.modal{
    .close{
        color: #fff;
        opacity: 1;
    }
    .cta{
        &:hover{
            padding-right: 4rem;
        }
    }
    
    form{
        /* a{
            color: $mainColor;
            font-weight: bold;
        } */
        .error{
            color: red;
        }
        /* .cta{
            background-color: $secondColor;
            color: #000;
            border: none;
            &::before{
                color: #000;
            }
            &:hover{
                color: #000;
            }
        } */
    }
}
#modalCookie{
    
    .modal-body{
        color: #000;
    }
    .modal-footer{
        border: none;
        .cta{
            background-color: $mainColor;
            &.accept-all{
                background-color: #795300;
            }
        }
    }
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link{
    background-color: #795300;
}

.account{
    .nav-tabs .nav-item .nav-link{
        display: inline-block;
        position: relative;
        &.active::before{
            content: "";
            width: 100%;
            height: 15px;
            background-color: #FFD457;
            border-radius: 30px;
            position: absolute;
            bottom: -4px;
            left: 0;
        }
    }
}

.preview{
    display: none;
    width: 50vw;
    position: absolute;
    top: 23px;
    right: -10px;
    background-color: #fff;
    z-index: 9;
    border: 1px solid $mainColor;
    border-radius: 0px;
    padding: 5px;
}
.preview ul li{
    background-color: rgba(0,0,0,0.1);
}
.preview .image{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $mainColor;
    height: 65px;
    padding: 2px;
    background-color: #fff;
}
.preview img{
    max-width: 100%;
    max-height: 100%;
}
.favorites-tooltip:hover .preview-favorites{
    display: block;
}
.cart-tooltip:hover .preview-cart{
    display: block;
}
.tool-count{
    position: absolute;
    top: -17px;
    border-radius: 8px;
    width: 30px;
    height: 20px;
    background-color: #fff;
    color: $mainColor;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border: 1px solid #000;
}
.cart-count{
    color: $mainColor;
}
.cart{
    .addresses-box {
        display: none;
        &.show{
            display: block;
        }
    }
    .account-data{
        label {
            font-weight: bold;
            font-size: 12px;
        }
        p {
            margin-bottom: 0.5rem;
        }
    }
    .box{
        border-radius: 20px;
        .box-header{
            border-radius: 20px 20px 0 0;
            background-color: #795300;
            h3{
                color: #fff;
            }
        }
    }
    .box-header{
        h1{
            color: #fff;
            font-weight: 600;
        }
    }
    .box-footer{
        border-radius: 0 0 20px 20px;
    }
    table td input.quantity-update{
        width: 55px!important;
        padding-right: 5px;
    }
} 
.delete-prod, .delete-favorite{
    color: $thirdColor;
}
.box.payment-method.active{
    border-width: 2px;
    transform: scale(1.05);
}
.payment-logo img{
    height: 50px;
}

.list-inline-item{
    cursor: pointer;
}
.select2-container--default .select2-selection--single{
    border: 1px solid #000;
    border-radius: 10px;
}
.card-header{
    background-color: #795300;
    h3{
        color: #fff;
    }
}

.carousel-indicators{
    bottom: 15px;
    li{
        background-color: transparent;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        display: grid;
        border: 1px solid transparent;
        place-items: center;
        opacity: 1;
        &::before{
            content: '';
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: $mainColor;
            position: absolute;
        }
        &.active{
            border-color: $mainColor;
        }
    }
}

/*
** MODULE BANNER
*/
.banner{
    .background{
        height: calc(100vh - #{$header-height-mobile});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .carousel-caption{
        bottom: 50px;
        color: #000;
        left: 5%;
        right: 5%;
        h2{
            font-size: 18px;
            font-weight: bold;
        }
        .image{
            background-color: #fff;
            border-radius: 30px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            width: 120px;
            aspect-ratio: .9;
            overflow: hidden;
            padding: 5px;
            display: grid;
            place-items: center;
            img{
                max-width: 100%;
            }
        }
    }
    .carousel-text{
        position: absolute;
        left: 5%;
        top: 10%;
        background-color: $mainColor;
        border-radius: 80px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        width: 250px;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        font-size: 18px;
        p{
            margin: 0;
        }
    }
}

/*
** MODULE PARALLAX
*/
.parallax{
    height: 100vh;
    .parallax-section{
        height: 100%;
        position: relative;
        overflow: hidden;
        .caption{
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%);
            color: #fff;
        }
        img{
            width: 100%;
        }
    }
}

/*
** MODULE GALLERY
*/
.gallery{
    .row > div{
        overflow: hidden;
        img{
            transition: all .5s ease-in-out;
            &:hover{
                transform: scale(1.1);
            }
        }
    }
}

/*
** MODULE ACCORDEON
*/
.accordeon{
    button{
        &:focus{
            box-shadow: none;
        }
    }
    h5{
        font-size: 15px;
        font-weight: 700;
        text-align: left;
    }
    .card-header{
        background: #FFFFFF;
        border-radius: 30px!important;
        border-bottom: none;
    }
    .icon{
        background-color: $secondColor;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        display: grid;
        place-items: center;
        flex-shrink: 0;
    }
    .accordion > .card {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
    }
}

/*
** MODULE CAROUSEL
*/
.carousel-module{
    overflow: hidden;
    .image{
        overflow: hidden;
        display: flex;
        justify-content: center;
        img{
            height: 200px;
            transition: all .5s ease-in-out;
        }
    }
    .center{
        .slick-center img{
            transform: scale(1.2);
        }
        .slick-prev {
            left: -40px;
        }
    }
    .slick-prev, .slick-next{
        top: calc(50% - 20px);
    }
    .slick-prev:before, .slick-next:before {
        color: $secondColor;
        font-size: 34px;
    }
    .slick-dots{
        bottom: -45px;
    }
}

/*
** MODULE BLOG
*/
.comment{
    .rate{
        i{
            font-size: 20px;
        }
        .rate-comment{
            outline: none;
            box-shadow: none;
            &.active{
                color: $secondColor;
            }
        }
    }
}
/*
**  MODULE CONTACT
*/
.contact{
    /* .cta{
        background-color: $secondColor;
        color: #000;
        border: none;
        &::before{
            color: #000;
        }
        &:hover{
            color: #000;
        }
    } */
    .element{
        background: #FFD457;
        border-radius: 20px;
        .icon{
            font-size: 30px;
        }
    }
}

.simpleText{
    ul li{
        font-size: 13px;
    }
}

/*
*    MASTER SHOP
*/
.qty-input{
    width: 53px;
    height: 28px;
    font-weight: 700;
    font-size: 15px;
    border-radius: 30px;
    text-align: center;
    border: none;
}
.qty-bts{
    background-color: $secondColor;
    color: $mainColor;
    height: 43px;
    width: 43px;
}

.stock{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    &.full{
        background-color: $green;
    }
    &.low{
        background-color: $yellow;
    }
    &.out{
        background-color: $red;
    }
}
.dropdown-item.select-variation{
    font-size: 11px;
    padding: .25rem .5rem;
    text-align: center;
}
.loader{
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: grid;
    place-items: center;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: 99999;
    i{
        font-size: 45px;
        color: $mainColor;
    }
}
.shop{
    section.newsletter{
        display: none;
    }
    .filter-nav{
        background-color: #fff;
        z-index: 999;
        transition: all .5s ease;
        position: absolute;
        top: $header-height-mobile;
        left: 50%;
        transform: translateX(-50%);
        &.scrolling{
            position: fixed;
        }
    }
}
.category-name{
    border-radius: 30px;
    background-color: #F3F3F3;
    padding: 10px 25px;
    display: inline-block;
    h1{
        font-size: 15px;
    }
}
.ranger {
    appearance: none;
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    border-radius: 5px;
    background: #000;
    outline: none;
    opacity: 0.8;
    transition: opacity 0.2s;
    -webkit-transition: .2s;
}
.ranger::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #fff; 
    box-shadow: 0 0 8px rgb(0 0 0 / 25%);
    cursor: pointer; /* Cursor on hover */
    border-radius: 50%;
}
  
.ranger::-moz-range-thumb {
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #fff; 
    box-shadow: 0 0 8px rgb(0 0 0 / 25%);
    cursor: pointer; /* Cursor on hover */
    border-radius: 50%;
}
.button-hover{
    border-radius: 30px;
    &:hover{
        background-color: #F3F3F3;
    }
}
.thankyou_note{
    color: #fff;
}
.card .card-header{
    border-radius: 20px 20px 0 0;
}
.dropdown-toggle{
    &.custom{
        border-radius: 30px;
        font-weight: 600;
        color: #000;
        padding: 0.375rem 1rem;
        &::after {
            display: inline-block;
            margin-left: .5rem;
            vertical-align: unset;
            content: "\f078";
            font-family: 'Font Awesome 5 Free';
            border: none;
            font-weight: 900;
        }
        & + .dropdown-menu{
            width: 100%;
            max-height: 50vh;
            overflow: auto;
            .dropdown-item:hover, .dropdown-item:focus {
                color: #000;
                background-color: #fff;
                border-radius: 20px;
            }
            &.show{
                z-index: 1040;
            }
        }    
        &:hover{
            background-color: #F3F3F3;    
        }
    }
    & + .dropdown-menu{
        background: #F3F3F3;
        box-shadow: 0px 0px 8px rgb(0 0 0 / 25%);
        border-radius: 20px;
    }
    
}
.show > .dropdown-toggle.custom{
    background-color: #F3F3F3;
}
.featured-products{
    .filters{
        width: 185px;
    }
    .dropdown-toggle{
        &.custom{
            &::after {
                margin-left: 1rem;
            }
        }
    }
}
.search{
    .page-item.active .page-link {
        background-color: $mainColor;
        border-color: $mainColor;
    }
    .page-link{
        color: #000;
        &:hover{
            color: $mainColor;
            background-color: #F3F3F3;
        }
    }
    .pagination{
        flex-wrap: wrap;
    }
}
.product{
    border-radius: 20px;
    border: 1px solid #D9D9D9;
    overflow: hidden;
    .image{
        position: relative;
        aspect-ratio: 1;
        overflow: hidden;
        .overlay{
            display: none;
        }
    }
    .badges{
        position: absolute;
        top: 15px;
        left: 15px;
        display: flex;
        flex-direction: column;
        .badge{
            border-radius: 30px;
            padding: .5rem 1rem;
            text-transform: uppercase;
            font-size: 15px;
            margin-bottom: .25rem;
            &.new{
                background-color: #8CCEE8;
            }
            &.promo{
                background-color: $mainColor;
            }
        }
    }
    .stars{
        color: $mainColor;
        position: absolute;
        top: 15px;
        right: 15px;
    }
    .free-shipping{
        display: inline-block;
        background-color: #8CCEE8;
        padding: .25rem .8rem;
        border-radius: 30px;
        font-size: 12px;
        /* position: absolute; */
        bottom: 15px;
        font-weight: 700;
        left: 50%;
        /* transform: translateX(-50%); */
        min-width: 130px;
    }
    .content{
        padding: 1rem .5rem .5rem;
    }
    .actions{
        border-radius: 30px;
        padding: 0 .25rem;
        .btn{
            padding: 0;
        }
    }
    .add-to-cart, .add-to-favorites, .product-has-variations{
        border-radius: 15px;
        width: 50%;
        height: 30px;
        display: grid;
        place-items: center;
        border: none;
        transition: all .5s ease-in-out;
        &:hover{
            width: 50px;
        }
    }
    .add-to-cart, .product-has-variations{
        background-color: $mainColor;
    }
    .add-to-favorites{
        background-color: #F3F3F3;
    }
    h3{
        font-size: 15px;
        font-weight: 700;
    }
    h4{
        font-size: 12px;
        font-weight: 700;
    }
    h5{
        font-size: 11px;
    }
    .price{
        .current-price.haspromo{
            color: $green;
        }
        .old-price{
            font-weight: normal;
        }
        .stock{
            margin-bottom: 2px;
        }
    }
}
.price, .pagination, .subtotal-cell, .shipping-cell, .tax-cell, .total-cell, .open-font{
    font-family: 'Open Sans', sans-serif;
}
.old-price{
    text-decoration: line-through;
    font-weight: normal;
}
.featured-home{
    .title::before{
        background-color: #fff;
    }
    .content{
        .text{
            padding-left: 20px;
            padding-right: 20px;
        }
        .action{
            padding-left: 20px;
        }
    }
    .image{
        height: 285px;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        &.left{
            .content{
                width: 225px;
            }
        }
        &.right{
            height: 255px;
            border-radius: 30px 30px 0 0;
            .content{
                width: 335px;
                .action{
                    padding: 0 15px;
                }
            }
            .floating-image{
                position: absolute;
                right: 5%;
                bottom: -5%;
                max-width: 50%;
                z-index: 1;
                transform: translateY(50%);
            }
        }
    }
    .background-main-color{
        background-color: $mainColor;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 20% 15px 15px;
        border-radius: 0px 0 30px 30px;
    }
}
.quotes{
    position: absolute;
    bottom: -70%;
    z-index: 1;
    h3{
        font-style: normal;
        font-weight: 700;
        font-size: 250px;
        line-height: 294px;
        color: #8CCEE8;
    }
}
.carousel-home{
    background-color: #F3F3F3;
    
    h5{
        font-size: 13px;
        font-weight: 700;
    }
    .slick-track{
        padding-top: .5rem;
        padding-bottom: .5rem;
    }
    .slide{
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0px 0px 8px rgb(0 0 0 / 25%);
        padding: 30px;
        width: 385px;
    }
    .slick-prev{
        left: -50%;
        &::before{
            content: '\f053';
        }
        &:hover{
            transform: translateY(-50%) rotateZ(-90deg)
        }
    }
    .slick-next{
        left: -45%;
        &::before{
            content: '\f054';
        }
        &:hover{
            transform: translateY(-50%) rotateZ(90deg)
        }
    }
    .slick-dots {
        bottom: -45px;
        left: 0;
    }
}

.slick-dots li button:before{
    color: $mainColor!important;
    opacity: 1!important;
}
.slick-dots li.slick-active button:before{
    border: 1px solid $mainColor!important;
    border-radius: 50%!important;
}

.slick-prev, .slick-next{
    border: 1px solid #795300;
    width: 35px;
    height: 35px;
    border-radius: 17.5px;
    top: 60%;
    transition: 1s all ease;
    &::before{
        background-color: transparent;
        color: #795300;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
    }
}

.related{
    .slick-dots{
        bottom: -45px;
    }
}
.resellers{
    .background{
        display: grid;
        place-items: center;
        background-color: $mainColor;
        border-radius: 20px;
        padding: 2.5rem 4rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .cta:hover{
            padding-right: 4rem;
        }
        h6{
            font-weight: 700;
            font-size: 26px;
        }
        .icon{
            font-size: 26px;
        }
    }
    .logo{
        h2{
            font-size: 55px;
        }
    }
    .actions{
        display: inline-grid;
        display: -ms-inline-grid;
        display: -moz-inline-grid;
    }
}
#resellerModal, #loginModal{
    h4{
        font-size: 22px;
    }
}
.newsletter{
    input.form-control{
        border-radius: 57px;
        background-color: #fff;
        height: 50px;
        box-shadow: 0px 2px 9px 3px rgb(0 0 0 / 25%);
        padding-left: 1.5rem;
    }
    .search-button{
        position: absolute;
        background-color: $secondColor;
        height: 40px;
        width: 40px;
        border-radius: 20px;
        right: 10px;
        display: grid;
        place-items: center;
        padding: 0;
        border: none;
        &:hover{
            width: 65px;
        }
    }
    .form-control{
        border: none;
        &:focus{
            border: 1px solid #F3F3F3;
        }
    }
    .form-group{
        max-height: 0;
        transition: all .4s ease;
        overflow: hidden;
        &.show{
            max-height: 145px;
            padding-top: 1rem;
        }
    }
    
}
#app .dropdown-toggle.custom + .dropdown-menu {
    width: auto;
}
.talk-to-us{
    position: fixed;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    bottom: 20px;
    right: 20px;
    background-color: #795300;
    border-radius: 50px;
    color: #A4C639;
    padding: 5px 25px;
    z-index: 1020;
    &::before{
        content: '';
        border: 15px solid transparent;
        border-top-color: #795300;
        width: 0;
        height: 0;
        position: absolute;
        bottom: -27px;
        right: 33px;
    }
    .icon{
        font-size: 22px;
        a{
            color: #A4C639;
        }
    }
}
.scroll-top{
    width: 34px;
    height: 34px;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    position: fixed;
    bottom: 40px;
    right: 25px;
    place-items: center;
    font-size: 25px;
    display: none;
    &.show{
        display: grid;
    }
}
.breadcrumb{
    border-radius: 30px;
    background-color: #F3F3F3;
    padding: 0.75rem 2rem;
}
.nav-tabs{
    border-bottom: 1px solid #000;
    padding-bottom: 1rem;
    .nav-link{
        border: none;
        opacity: .5;
        font-weight: 700;
        &.active{
            color: #000;
            opacity: 1;
        }
    }
}
.font-style-italic{
    font-style: italic;
}
.detail{
    .prod-name{
        h1{
            font-size: 20px;
            font-weight: 600;
        }
    }
    .rating{
        color: $mainColor;
        .reviews{
            color: #A6A6A6;
            font-family: 'Open Sans', sans-serif;
        }
    }
    .prod-price{
        font-family: 'Open Sans', sans-serif;
        .buy, .wrap{
            border-radius: 30px;
            padding: .25rem .5rem;
            display: flex;
            align-items: center;
            height: 42px;
        }
        .dropdown-toggle.custom{
            height: 52px;
        }
        .buy{
            background-color: $mainColor;
        }
        .wrap{
            background-color: #F3F3F3;
            .price{
                font-family: 'Open Sans', sans-serif;
                font-weight: 700;
                font-size: 30px;
                &.promo{
                    color: #458C23;
                }
            }
        }
    }
    .body{
        .add-to-favorites, .share{
            background-color: $mainColor;
            border-radius: 20px;
            display: grid;
            place-items: center;
            width: 80px;
            height: 35px;
            transition: all 0.5s ease-in-out;
            &:hover{
                width: 55px;
            }
        }
    }
    .add-to-cart{
        span{
            font-weight: 700;
            font-size: 15px;
        }
    }
    .wrapper-bg{
        background-color: #F3F3F3;
    }
    .nav-tabs {
        border-bottom: none;
    }
    .options{
        .nav-item{
            .nav-link{
                font-size: 15px;
                font-weight: 700;
                opacity: .5;
                border: none;
                background-color: #D9D9D9;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                text-align: center;
                padding: 1.5rem 1rem;
                &.active{
                    opacity: 1;
                    background-color: #F3F3F3;
                }
            }
        }
        .stars i{
            font-size: 20px;
        }
        .review-image{
            max-width: 200px;
        }
    }
    .review-product{
        .stars i{
            font-size: 20px;
        }
        button.btn{
            background-color: $secondColor;
            border-radius: 30px;
            font-weight: 600;
            font-size: 13px;
            border-color: $secondColor;
            color: #000;
            &::before{
                color: #000;
            }
        }
        small{
            font-size: 10px;
        }
    }
    #carousel-mobile{
        .image{
            border: 1px solid #F3F3F3;
            border-radius: 20px;
            overflow: hidden;
            .badges{
                position: absolute;
                top: 15px;
                left: 15px;
                display: flex;
                flex-direction: column;
                .badge{
                    border-radius: 30px;
                    padding: .5rem 1rem;
                    text-transform: uppercase;
                    font-size: 15px;
                    margin-bottom: .25rem;
                    &.new{
                        background-color: #8CCEE8;
                    }
                    &.promo{
                        background-color: $mainColor;
                    }
                }
            }
            .free-shipping{
                display: inline-block;
                background-color: #8CCEE8;
                padding: .5rem .8rem;
                border-radius: 30px;
                font-size: 15px;
                position: absolute;
                bottom: 15px;
                font-weight: 700;
                left: 50%;
                transform: translateX(-50%);
                min-width: 130px;
            }
        }
        .carousel-indicators{
            bottom: -20%;
        }
    }
    .accordeon .accordion{
        .card-header{
            border-radius: 0!important;
            padding: 0.75rem 0;
            background-color: #F3F3F3;
        }
        .card-body{
            padding: 0.375rem 0.75rem;
            .wrapper{
                border-top: 2px solid #000;
            }
        }
        & > .card{
            background-color: #F3F3F3;
            box-shadow: none;
            border-radius: 0;
        }
        h2{
            font-size: 15px;
            font-weight: bold;
        }
        .icon{
            background-color: transparent;
        }
    } 
}
#reviewModal, #contactModal, #resellerModal, #loginModal{
    .close{
        color: #000;
        text-align: right;
        padding: 1rem 1rem 0 0;
        font-size: 24px;
    }
}
.card{
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 20px;
    box-shadow: none;
}
.shadow {
    box-shadow: 0 0 1rem rgb(0 0 0 / 15%) !important;
}
.main-color{
    color: $mainColor;
}
.second-color{
    color: $secondColor;
}
.third-color{
    color: $thirdColor;
}
span.error{
    color: $red;
}
.review-form{
    .stars{
        .star-input{
            color: $mainColor;
            padding: 0;
            font-size: 20px;
            position: relative;
            width: 25px;
            height: 25px;
            display: inline-block;
            i{
                position: absolute;
                top: 0;
                left: 0;
            }
            .fas.fa-star{
                opacity: 0;
            }
            .far.fa-star{
                opacity: 1;
            }
            &.active{
                .fas.fa-star{
                    opacity: 1;
                }
                .far.fa-star{
                    opacity: 0;
                }
            }
        }
    }
    
    label{
        i{
            font-size: 25px;
        }
    }
}
.floating-actions{
    position: fixed;
    bottom: 0;
    left: 0;
    background: #D9D9D9;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 20px 20px 0px 0px;
    padding: 10px;
    z-index: 1040;
    width: 100%;
    .prod-price .wrap{
        background-color: #fff;
        .price{
            font-size: 20px;
        }
    }
    .dropdown-toggle.custom + .dropdown-menu{
        width: auto;
    }
}
.list-inline-item{
    &.active{
        font-size: 1rem;
        font-weight: 700;
    }
}
.border-style-1{
    border: 1px solid #795300;
    border-radius: 30px;
    background-color: #795300;
}
.text-uppercase{
    letter-spacing: unset;
}
.select2-container{
    width: 100%!important;
}
.timeline{
    .event{
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0px 0px 8px rgb(0 0 0 / 25%);
        height: 289px;
    }
    .slick-track {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    .date{
        font-size: 20px;
    }
}

#order-summary{
    border-radius: 20px;
    .box-header{
        border-radius: 20px 20px 0 0;
    }
}

.sidebar{
    padding: 10px;
    position: fixed;
    z-index: 9999;
    background-color: #fff;
    right: -345px;
    width: 340px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    border-radius: 20px 0 0 20px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    &.active{
        right: 0;
    }
}
.back{
    a{
        font-size: 16px;
    }
}

.show-pass{
    i{
        font-size: 20px;
    }
    &.active{
        color: $thirdColor;
    }
}

.border-top-2{
    border-top: 2px solid #212529!important;
}
.vertical-align-bottom{
    vertical-align: bottom!important;
}

@media (min-width: 576px){

    main{
        padding-top: $header-height-tablet;
    }
    .modal .cart-actions{
        display: flex;
    }
    .featured-home{
        .image{
            height: 50vw;
            &.left{
                .content{
                    width: 235px;
                }
            }
        }
    }

}

@media (min-width: 768px){

    .featured-home .image.left .content {
        width: 300px;
    }

}

@media(min-width: 992px){

    
    h4, h2, h1{
        font-size: 26px;
    }
    .anchor-scroll{
        position: absolute;
        top: -155px;
    }
    .cta{
        font-size: 15px;
        &::before{
            width: 28px;
            height: 28px;
        }
    }
    .title{
        h1, h2, h3, h4{
            font-size: 26px;
        }
    }
    .resellers{
        background-size: contain;
    }
    .quotes{
        top: -53px;
        bottom: unset;
    }
    #resellerModal{
        .modal-dialog {
            max-width: 625px;
        }
        h4{
            font-size: 26px;
        }
    }
    #loginModal{
        .modal-dialog {
            max-width: 792px;
        }
        h4{
            font-size: 26px;
        }
    }
    .featured-home{
        .image{
            height: 100%;
            &.left .content {
                width: auto;
            }
        }
    }
    .wrapper-bg-lg{
        background-color: #F3F3F3;
    }
    .sidebar{
        padding: 10px;
        position: fixed;
        z-index: 9999;
        background-color: #fff;
        right: -405px;
        width: 400px;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        border-radius: 20px 0 0 20px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
        &.active{
            right: 0;
        }
    }

    .preview{
        width: 30vw;
        transform: translateX(50%);
        right: 50%;
        top: 100%;
    }

    .pagination{
        justify-content: center;
    }

    /*
    ** MODULE BANNER
    */
    .banner{
        .background{
            height: calc(88vh - #{$header-height});
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
        .carousel-caption{
            right: auto;
            left: 15%;
            color: #000;
            text-align: left;
            top: 20%;
            bottom: unset;
            padding: 0;
            h2{
                font-weight: bold;
                font-size: 26px;
            }
            .image{
                border-radius: 30px;
                width: 120px;
                aspect-ratio: .9;
                padding: 5px;
                display: grid;
                place-items: center;
                img{
                    max-width: 100%;
                }
            }
        }
        .carousel-text{
            left: unset;
            top: unset;
            right: 5%;
            bottom: 10%;
            width: 320px;
            padding: 10px 20px;
            display: flex;
            align-items: center;
            font-size: 18px;
            p{
                margin: 0;
            }
        }
        .rect-1{
            position: absolute;
            width: 130px;
            height: 130px;
            left:-65px;
            bottom: 50%;
            background: #8CCEE8;
            border-radius: 75px;
        }
        .rect-2{
            position: absolute;
            width: 320px;
            height: 130px;
            right: -160px;
            bottom: 15%;
            background: #8CCEE8;
            border-radius: 75px;
        }
        .elipse{
            width: 100%;
            height: 50px;
            background-color: #fff;
            clip-path: ellipse(50% 40% at 50% 100%);
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    .accordeon{
        h5{
            font-size: 18px;
        }
    }

    /*
    ** CART
    */
    #basket{
        td.actions{
            width: 35px;
            text-align: right;
        }
    }

    .detail{
        .gallery {
            height: 100%;
        }
        #vertical-thumbs{
            .slick-next, .slick-prev{
                left: -40px;

            }
            .slick-prev{
                top: 45%;
                &:before {
                    content: "\f106";
                    font-size: 25px;
                }
            }
            .slick-next{
                top: 55%;
                &:before{
                    content: "\f107";
                    font-size: 25px;
                }
            }
        }
        .free-shipping{
            display: inline-block;
            background-color: #8CCEE8;
            padding: .5rem .8rem;
            border-radius: 30px;
            font-size: 15px;
            font-weight: 700;
            min-width: 130px;
        }
        #stage{
            .stage-item{
                white-space: normal;
                .embed-responsive-item img {
                    width: 100%;
                    /* max-height: 100%; */
                    position: absolute;
                    top: 0;
                    left: 0;
                    border: 1px solid #F3F3F3;
                    border-radius: 20px;
                    border-radius: 15px;
                }
                .badges{
                    position: absolute;
                    top: 15px;
                    left: 15px;
                    display: flex;
                    flex-direction: column;
                    .badge{
                        border-radius: 30px;
                        padding: .5rem 1rem;
                        text-transform: uppercase;
                        font-size: 15px;
                        margin-bottom: .25rem;
                        &.new{
                            background-color: #8CCEE8;
                        }
                        &.promo{
                            background-color: $mainColor;
                        }
                    }
                }
            } 
            & > .gallery {
                white-space: nowrap;
            }
        } 
        .slick-list {
            overflow: hidden;
            height: 100% !important;
        }
        .thumb-item {
            padding: 0px 10px 20px;
            .thumb{
                img {
                    max-width: 100%;
                    max-height: 100%;
                    margin: 0 auto;
                    border-radius: 20px;
                }
            }
            &.slick-current.slick-active img, .detail .thumb-item:hover img {
                border: 1px solid $mainColor;
            }
        }
        .embed-responsive-2by1 {
            padding-bottom: 100%;
        }
        .options{
            .nav-item{
                .nav-link{
                    background-color: transparent;
                    &.active{
                        background-color: #F3F3F3;
                    }
                }
            }
        }
        .buy:hover{
            background-color: #fff;
            box-shadow: 0px 0px 8px rgb(0 0 0 / 25%);
        }
        .body{
            .add-to-favorites, .share{
                width: 40px;
                height: 40px;
            }
        } 
        .review-product{
            button.btn{
                font-size: 15px;
            }
        }
        .prod-price{
            .buy, .wrap{
                padding: .5rem 1rem;
                height: 52px;
            }
        }
    }
    .floating-actions{
        bottom: 20px;
        left: auto;
        right: 40px;
        width: auto;
        /* min-width: 600px; */
        border-radius: 40px;
        background-color: #fff;
        box-shadow: 0px 0px 8px rgb(0 0 0 / 25%);
        display: none;
        &.show{
            display: block;
        }
        .prod-price .wrap{
            background-color: #F3F3F3;
            .price{
                font-size: 30px;
            }
        }
        .add-to-favorites{
            background-color: $mainColor;
            border-radius: 20px;
            transition: all 0.5s ease-in-out;
            width: 40px;
            height: 40px;
            &:hover{
                width: 55px;
            }
        }
    }
    .newsletter{
        .form-bar{
            width: 500px;
        }
    }
    
    .embed-responsive-16by9::before {
        aspect-ratio: 1;
    }
}

@media (min-width: 1200px){

    main{
        padding-top: $header-height;
        min-height: calc(100vh - #{$footer-height});
    }
    .container{
        max-width: 1160px;
    }
    .home{
        main{
            padding-top: 197px;
        }
        .navbar-laravel{
            padding: .5rem 0 0;
            .bottom-header{
                top: 24px;
                background-color: transparent;
            }
            .top-bar{
                max-height: 32px;
                overflow: visible;
            }
            .navbar-brand img{
                width: 250px;
            }
            .support .text h5{
                font-size: 21px;
            }
            &.scrolling{
                box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 40%);
                padding: 5px 0 0;
                .advertise{
                    max-height: 0px;
                    padding: 0;
                }
                .bottom-header {
                    top: 5px;
                    background-color: $mainColor;
                    padding: 5px 0;
                }
                .top-bar{
                    max-height: 0;
                    overflow: hidden;
                }
                .navbar-brand img{
                    width: 200px;
                }
                .support .text h5{
                    font-size: 15px;
                }
            }
        }
    }
    .navbar-laravel {
        padding: 5px 0 0;
        transition: all .4s ease-in-out;
        .cart-thumbnail{
            .image{
                aspect-ratio: 1.5;
                display: grid;
                place-items: center;
                overflow: hidden;
                border-radius: 20px;
                img{
                    min-width: 100%;
                    min-height: 100%;
                }
            }
        }
        .top-bar{
            display: flex;
            justify-content: flex-end;
            transition: all .4s ease-in-out;
            max-height: 0;
            padding: 0 3rem;
            overflow: hidden;
            .nav-item{
                .nav-link{
                    color: $mainColor;
                    font-weight: normal;
                }
            }
            .dropdown-menu{
                background-color: $mainColor;
                .dropdown-item{
                    color: #fff;
                    font-weight: bold;
                    &:hover, &:focus{
                        background-color: #795300;
                        color: $mainColor;
                    }
                }
            }
        }
        .top-header{
            padding: 0 3rem;
        }
        .bottom-header {
            top: 5px;
            background-color: $mainColor;
            padding: 5px 0;
            position: relative;
            transition: all .4s ease-in-out;
            width: 100%;
        }
        .advertise{
            max-height: 0px;
            padding: 0;
        }
        .navbar-brand img{
            width: 200px;
        }
        .flag{
            width: 20px;
        }
        .navbar-toggler{
            i{
                font-size: 30px;
            }
        }
        .product-cart-list{
            max-height: 70vh;
            overflow-y: auto;
        }
        .navbar-nav{
            .nav-item.dropdown.show{
                .dropdown-menu{
                    opacity: 1;
                    /* top: 70%; */
                    visibility: visible;
                }
            }
            li.lang{
                .dropdown-menu{
                    min-width: 75px;
                    text-align: center;
                    padding: 5px;
                    .dropdown-item{
                        color: #fff;
                        &:hover{
                            background-color: $thirdColor;
                            color: $mainColor;
                        }
                    }
                } 
            }
            .cart-drop{
                & + .dropdown-menu{
                    right: -120px;
                    left: auto;
                    min-width: 20rem;
                    .wrapper{
                        border: 1px solid $mainColor;
                        border-radius: 5px;
                        padding: 1px;
                    }
                }    
            }
            .dropdown-menu{
                position: absolute;
                top: 70%;
                -webkit-transition: all .25s ease-in 0s;
                transition: all .25s ease-in 0s;
                opacity: 0;
                visibility: hidden;
                padding: 5px;
                display: block;
                border: none;
            }
            
            .nav-item{
                .nav-link{
                    font-size: 14px;
                    padding: 3px 10px;
                }
            }
        }
        .menu-assets{
            .navbar-nav{
                .nav-item{
                    display: flex;
                    align-items: center;
                    .nav-link{
                        color: $mainColor;
                        font-size: 16px;
                        font-weight: normal;
                    }
                }
                .dropdown-menu{
                    background-color: $mainColor;
                    padding: 5px 8px;
                    border-radius: 0.25rem;
                    .nav-link{
                        color: #fff;
                        font-weight: 700;
                        &:hover{
                            color: $mainColor;
                        }
                    }
                }
                .dropdown-toggle:not(#navbarDropdownLangLink){
                    &::after{
                        content: unset;
                    }
                }
            }
        }
        .account-drop{
            & + .dropdown-menu{
                min-width: 11rem;
            }
        }
        .cart-dropdown{
            .dropdown-menu{
                right: -10px;
                left: auto;
                .product-cart-list{
                    width: 380px;
                }
            }
        }
        .menu{
            .nav-item{
                margin: 0 8px;
                .nav-link{
                    background-color: $mainColor;
                    border-radius: 30px;
                    height: 50px;
                    padding: 3px 15px;
                    font-size: 15px;
                    &:hover{
                        color: #000;
                        background-color: #fff;
                        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
                    }
                    &.dropdown-toggle::after{
                        content: unset;
                    }
                    &.cat{
                        &.active{
                            color: #000;
                            background-color: #fff;
                            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
                        }
                    }
                }
                &.dropdown{
                    position: static;
                    &.show{
                        .dropdown-toggle{
                            background-color: #fff;
                            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
                        }
                    }
                }
            }
            .dropdown-menu{
                background-color: transparent;
                padding: 25px 0 0;
                box-shadow: none;
                width: 90vw;
                .scroller{
                    max-height: calc(100vh - 210px);
                }
                .wrapper{
                    padding: 25px 15px 5px;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
                    border-radius: 20px;
                }
            }
            .subcat{
                .image{
                    background: #D9D9D9;
                    border-radius: 20px;
                    width: 200px;
                    height: 123px;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }
                .name{
                    max-width: 200px;
                    h6{
                        font-weight: bold;
                        font-size: 13px;
                    }
                    a:hover, a.active{
                        text-decoration: underline;
                    }
                }
                .sub-subcat .nav-item{
                    margin: 0;
                    .dropdown-item{
                        font-size: 13px;
                        line-height: 1.1;
                    }
                }
            }
            .dropdown-item{
                border-left: 2px solid $mainColor;
                margin-bottom: 10px;
                font-weight: bolder;
                background-color: transparent;
                color: #000;
                padding: 0 5px;
                &:hover, &.active{
                    border-width: 4px;
                    padding-left: 10px;
                }
            }
        }
    }
    #order-summary{
        .total-cell{
            padding: .75rem 0;
        }
    }
    .featured-home{
        .image{
            height: 415px;
            &.left{
                .content{
                    width: 308px;
                }
            }
            &.right{
                height: 415px;
                border-radius: 30px 0 0 30px;
                .floating-image{
                    position: absolute;
                    right: 0;
                    bottom: 5%;
                    max-width: 50%;
                    transform: translateX(50%);
                    z-index: 1;
                }
            }
        }
        .background-main-color{
            background-color: $mainColor;
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0 15px 0 33%;
            justify-content: flex-end;
            border-radius: 0px 30px 30px 0px;
        }
    }
    .product{
        .image{
            /* height: 250px; */
            display: flex;
            justify-content: center;
            /* aspect-ratio: unset; */
            align-items: center;
            border-radius: 20px 20px 0px 0px;
            overflow: hidden;
            img{
                transition: all .4s ease-in-out;
                width: 100%;
                will-change: transform;
            }
            &:hover{
                img{
                    transform: scale(1.05);
                }
                .overlay{
                    bottom: 80px;
                }
            } 
            .overlay{
                position: absolute;
                left: 50%;
                bottom: -40px;
                transform: translateX(-50%);
                font-size: 22px;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: grid;
                place-items: center;
                transition: all .5s ease;
                background: rgba(255 255 255 / 50%);
                &.show{
                    bottom: 80px;
                }
            }
        }
        h4{
            font-size: 13px;
            font-weight: 700;
            display: inline-block;
            transition: all .3s ease;
            &.name{
                padding-bottom: 3px;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        h5{
            font-size: 13px;
            font-weight: 400;
        }
        .add-to-cart, .add-to-favorites, .product-has-variations{
            width: 30px;
        }
        .content{
            padding: 1rem 1rem .5rem;
        }
        .actions {
            background: #F3F3F3;
            padding: 0.5rem 0.75rem;
        }
        .add-to-favorites {
            background-color: #fff;
        }
    }
    .shop{
        .filter-nav{
            background-color: #fff;
            z-index: 999;
            transition: all .5s ease;
            position: absolute;
            top: $header-height;
            left: 50%;
            transform: translateX(-50%);
            &.scrolling{
                position: fixed;
            }
        }
    }
    .newsletter{
        input.form-control{
            height: 60px;
        }
    }
    .footer{
        padding: 15px 20px;
        .nav-link{
            font-size: 12px;
        }
        p{
            font-size: .9rem;
        }
        .social{
            a{
                font-size: 20px;
            }
        }
    }
    .resellers{
        .actions{
            display: block;
        }
        .image{
            min-height: 550px;
        }
    }
    .cart{
        table{
            th.total{
                min-width: 100px;
            }
            th.subtotal-cell{
                min-width: 90px;
            }
        }
        
    }
}

@media (min-width: 1200px) and (max-width: 1450px){

    .navbar-laravel .menu .nav-item .nav-link{
        padding: 3px 9px;
    }

}

@media (min-width: 1200px) and (max-width: 1499px){

    .featured-home{
        .left .content{
            text-align: right;
        }
    }

}

@media (min-width: 1200px) and (max-height: 750px){

    .navbar-laravel .menu .subcat .image{
        width: 162px;
        height: 100px;
    }
    .navbar-laravel .menu .scroller .nav-item {
        margin-bottom: 5px!important;
    }

}

@media (min-width: 1350px){

    .container{
        max-width: 1250px;
    }
    .navbar-laravel {
        .menu{
            .dropdown-menu{
                width: 80vw;
                .scroller{
                    max-height: calc(100vh - 205px);
                }
            }
            .nav-item{
                margin: 0 15px;
            }
        }
    }

}

@media (min-width: 1500px){

    .container{
        max-width: 1400px;
    }
    .navbar-laravel {
        .menu{
            .dropdown-menu{
                width: 85vw;
                .scroller{
                    max-height: calc(95vh - #{$header-height});
                }
            }
        }
    }
    .detail{
        .gallery {
            min-height: 400px;
        }
    }
}

@media (min-width: 1600px){

    .container{
        max-width: 1450px;
    }
    .preview{
        width: 22vw;
    }
    .navbar-laravel {
        .menu{
            .dropdown-menu{
                width: 1410px;
            }
        }
    }
    .banner{
        .carousel-caption{
            .image{
                width: 150px;
            }
        }
    }
}

@media (max-width: 1399px){

    .resellers{
        .image img{
            max-width: 100%;
        }
    }

}

@media (max-width: 1199px){

    .product{
        .image{
            img{
                max-width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

}

@media (max-width: 991px){

    body.shop.detail{
        padding-bottom: 75px;
    }
    .bread-container{
        display: none;
    }
    .resellers{
        .image img{
            max-width: 100%;
        }
    }
    .tooltip{
        display: none;
    }
    .detail{
        .carousel-control-next, .carousel-control-prev{
            i{
                font-size: 40px;
                width: 40px;
                height: 40px;
                background-color: #fff;
                border-radius: 50%;
                color: $mainColor;
            }
        }
        .options .nav-item .nav-link{
            opacity: 1;
        }
    }
}

@media (max-width: 575px){

    /*
    ** MODULE CAROUSEL
    */
    .carousel-module{
        .slick-prev {
            left: -10px;
        }
        .slick-next{
            right: 0;
        }
    }
    .navbar-brand img{
        max-width: 155px;
    }
    .floating-actions{
        .prod-price{
            .wrap{
                display: grid;
                .old-price{
                    font-size: 14px;
                }
                height: 45px;
            }
            .buy{
                height: 45px;
            }
        }
    }
    .box .box-footer, .box .box-header{
        padding: 10px 20px;
    }
    .cart{
        h1, h3{
            font-size: 18px;
        }
    }

}

@media (max-width: 499px){

    .cart-mobile{
        .info table tbody tr td{
            text-align: right;
            input.quantity-update{
                margin-left: auto;
                text-align: right;
            }
        }
    }

}